import { OrderPlan, OrderRecurringSettings, OrderCheckItem } from '~/framework/server-api/typeAliases';
import {
  DriverAssignmentType,
  DriverType,
  MarginType,
  OrderCreatedVia,
  OrderSchedulingPriority,
  OrderStatus,
  PreloadStatus,
} from '~/framework/domain/typeAliases';
import { OrderGroupEntity } from '~/framework/domain/masters/order-group/orderGroupEntity';
import { Maybe } from '~/framework/typeAliases';
import { UserEntity } from '~/framework/domain/masters/user/userEntity';
import { AggregatedGenerationSiteTaskEntity } from '~/framework/domain/schedule/order/generation-site-task/aggregatedGenerationSiteTaskEntity';
import { IrregularTaskEntity } from '~/framework/domain/schedule/order/irregular-task/irregularTaskEntity';
import { IllegalStateException } from '~/framework/core/exception';
import { AggregatedClientEntity } from '~/framework/domain/masters/client/aggregatedClientEntity';
import { AggregatedDisposalSiteEntity } from '~/framework/domain/masters/disposal-site/aggregatedDisposalSiteEntity';
import { AggregatedCarEntity } from '~/framework/domain/masters/car/aggregatedCarEntity';
import { createLogger } from '~/framework/logger';
import { DateCollectablePeriodItem } from '~/components/panels/schedule/r-order-form/dateCollectablePeriodItem';
import { IAggregatedOrderAssignedDisposalSitesAndType } from '~/framework/server-api/schedule/order/disposal-site/disposalSite';
import { OrderCandidateDate, OrderFixedPlan, UploadedFile } from '~/graphql/graphQLServerApi';
import { IOrderRoutingGroup } from '~/framework/server-api/schedule/order/orderRoutingGroup';
import { IOrderData } from '~/framework/server-api/schedule/order/order';
import { SimpleCarTypeEntity } from '../../masters/car-type/aggregatedCarTypeEntity';
import { mapEntity } from '~/framework/core/mapper';
import { AggregatedGenerationSiteEntity } from '../../masters/generation-site/aggregatedGenerationSiteEntity';
import { IAggregatedOrderAssignableDriver, getDriverEntities } from './driver/aggregatedOrderAssignableDriver';
import { IUpdateOrderData } from '~/framework/server-api/schedule/order/updateOrders';
import { convertOrderPlanToOrderPlanInput } from './orderUtils';
import { ids } from '~/framework/core/entity';
import { format } from 'date-fns';

// PostponeOrder(複数候補日を明日以降に送る)が実行されたかどうかを示す
export enum PostponeOrderStatus {
  Default = 'default',
  Success = 'success',
  Failure = 'failure',
}

export class PseudoOrderEntity {
  /**
   * 瞬間チェック後の作成不可の表示など、OrderEntityとしては登録されていないが、
   * OrderEntityのように（一部の情報を用いて）ふるまってほしいときに用いるためのクラス
   * OrderEntityに継承を行う
   */
  readonly id: string;
  readonly persistentId: string;
  plan: OrderPlan;
  client: AggregatedClientEntity;
  generationSite: AggregatedGenerationSiteEntity;
  preloadStatus: PreloadStatus;
  postponeOrderStatus: PostponeOrderStatus;
  assignableCars: AggregatedCarEntity[];
  assignableCarTypes: SimpleCarTypeEntity[];
  fixedArrivalTime: Maybe<number>;

  get activePlan(): OrderFixedPlan | OrderCandidateDate {
    if (this.plan.fixed !== undefined) return this.plan.fixed;

    if (this.plan.candidateDates !== undefined && this.plan.candidateDates.length > 0) {
      return this.plan.candidateDates[0];
    }

    throw new Error('both plan.fixed and plan.candidateDates are not defined');
  }

  constructor(
    id: string,
    persistentId: string,
    plan: OrderPlan,
    client: AggregatedClientEntity,
    generationSite: AggregatedGenerationSiteEntity,
    preloadStatus: PreloadStatus,
    postponeOrderStatus: PostponeOrderStatus,
    assignableCars: AggregatedCarEntity[],
    assignableCarTypes: SimpleCarTypeEntity[],
    fixedArrivalTime: Maybe<number>
  ) {
    this.id = id;
    this.persistentId = persistentId;
    this.plan = plan;
    this.client = client;
    this.generationSite = generationSite;
    this.preloadStatus = preloadStatus;
    this.postponeOrderStatus = postponeOrderStatus;
    this.assignableCars = assignableCars;
    this.assignableCarTypes = assignableCarTypes;
    this.fixedArrivalTime = fixedArrivalTime;
  }
}

export class AggregatedOrderEntity extends PseudoOrderEntity {
  code: Maybe<string>;
  serialNumber: number;
  durationAtGenerationSite: number;
  routeCollectionAllowed: boolean;
  assignAssignableDriversOnUnloadDate: Maybe<boolean>;
  orderAssignedDisposalSitesAndType: IAggregatedOrderAssignedDisposalSitesAndType;
  driverAssignmentType: DriverAssignmentType;
  assignableDrivers: IAggregatedOrderAssignableDriver[];
  carNum: number;
  minAssignedDriverNum: number;
  maxAssignedDriverNum: number;
  note: Maybe<string>;
  noteForAssignedDriver: Maybe<string>;
  noteFromReservation: Maybe<string>;
  attachments: UploadedFile[];
  avoidHighways: boolean;
  isFixedArrivalTimeReportNeeded: boolean;
  marginTypeOfFixedArrivalTime: MarginType;
  marginOfFixedArrivalTime: number;
  orderCheckItems: OrderCheckItem[];
  routingGroup: Maybe<IOrderRoutingGroup>;
  fixedDisplayOnReservation: boolean;
  fixedDisplayOnReservationName: Maybe<string>;
  schedulingPriority: OrderSchedulingPriority;
  recurringSettings: Maybe<OrderRecurringSettings>;
  status: OrderStatus;
  createdVia: OrderCreatedVia;
  createdBy: UserEntity;
  createdAt: Date;
  updatedBy: UserEntity;
  updatedAt: Date;
  orderGroup: OrderGroupEntity;

  /**
   * コンテナありのタスク
   */
  generationSiteTasks: AggregatedGenerationSiteTaskEntity[];
  /**
   * その他のタスク
   */
  irregularTasks: IrregularTaskEntity[];
  // 複数候補日を明日以降に送る処理(postponeOrders)の実行結果
  // 受注が編集された場合にその受注が配車表Scheduleのどの日付から開かれたかを示す、複数候補日から特定の日付を削除する為に使う
  editScheduleDate: Maybe<Date>;

  /**
   * 現場タスクのチェックと更新を行う
   *
   * @param generationSiteTasks
   * @param irregularTasks
   */

  get assignedDisposalSites(): AggregatedDisposalSiteEntity[] {
    return this.orderAssignedDisposalSitesAndType.orderDisposalSites.map(
      (orderDisposalSite) => orderDisposalSite.disposalSite
    );
  }

  setGenerationSiteTasks(
    generationSiteTasks: AggregatedGenerationSiteTaskEntity[],
    irregularTasks: IrregularTaskEntity[]
  ): void {
    let types = 0;
    if (0 < generationSiteTasks.length) types++;
    if (0 < irregularTasks.length) types++;
    if (types !== 1) {
      const status = {
        generationSiteTasks: generationSiteTasks.length,
        irregularTasks: irregularTasks.length,
      };
      logger.addBreadcrumb({
        type: 'error',
        message: 'setGenerationSiteTasks',
        data: {
          status,
          generationSiteTasks: JSON.stringify(generationSiteTasks),
          irregularTasks: JSON.stringify(irregularTasks),
        },
      });
      throw new IllegalStateException(
        `Should specify one generation site task type! orderId: ${this.id}, types: ${JSON.stringify(status)}`
      );
    }
    this.generationSiteTasks = generationSiteTasks;
    this.irregularTasks = irregularTasks;
  }

  /**
   * 指定日が荷下ろし日と一致するかどうか
   * @param date
   * @returns {boolean}
   */
  isFixedUnloadDate(date: Date): boolean {
    // NOTE: 複数候補日の場合は fixed でないので false を返す
    if (this.plan === undefined || this.plan.fixed === undefined || this.plan.fixed.unloadDate === undefined)
      return false;
    return format(date, 'yyyy/MM/dd') === format(this.plan.fixed.unloadDate, 'yyyy/MM/dd');
  }

  // 複数候補日を持っているかどうか
  // 単数の場合でも複数の場合でも共通のDateCollectablePeriodItemの配列として返す
  hasCandidateDates(): boolean {
    return !!this.plan?.candidateDates;
  }

  // 単数、複数を問わない候補日の配列データ
  // plan内にfixedがある場合はfixedのDateを返す、candidateDatesがある場合はその中の最初のDateを返す
  orderPlanCandidateDates(): DateCollectablePeriodItem[] {
    // 単数が存在する場合は単数のみ
    if (this.plan.fixed !== undefined) {
      return [
        new DateCollectablePeriodItem(
          this.plan.fixed.date,
          this.plan.fixed.collectablePeriodTemplateName,
          this.plan.fixed.collectablePeriodStart,
          this.plan.fixed.collectablePeriodEnd,
          this.plan.fixed.unloadDate
        ),
      ];
    }
    // 複数が存在する場合は複数のみ
    if (this.plan.candidateDates !== undefined) {
      return this.plan.candidateDates.map((item) => {
        return new DateCollectablePeriodItem(
          item.date,
          item.collectablePeriodTemplateName,
          item.collectablePeriodStart,
          item.collectablePeriodEnd,
          item.unloadDate
        );
      });
    }
    throw new Error('plan is not defined');
  }

  getFirstDate(): Date {
    // fixedがある場合はfixedのdateを返す
    if (this.plan.fixed !== undefined) {
      return this.plan.fixed.date;
    }
    // candidateDatesがある場合はその中の最初のdateを返す
    if (this.plan.candidateDates !== undefined && 0 < this.plan.candidateDates.length) {
      return this.plan.candidateDates[0].date;
    }
    throw new Error('plan is not defined');
  }

  getDateType(): 'candidate' | 'candidate-preload' | 'preload' | 'recurring' | 'normal' {
    // パターン
    // - 通常: normal
    // - 複数候補日あり: candidate
    // - 複数候補日あり・宵積みあり: candidate-preload
    // - 宵積みあり: preload
    // - 繰り返し受注: recurring
    if (this.hasCandidateDates() && this.preloadStatus === PreloadStatus.Forced) return 'candidate-preload';
    if (this.hasCandidateDates()) return 'candidate';
    if (this.preloadStatus === PreloadStatus.Forced) return 'preload';
    if (this.recurringSettings !== undefined) return 'recurring';
    return 'normal';
  }

  hasAssignableDrivers(): boolean {
    return this.assignableDrivers.length > 0;
  }

  /**
   * 乗務員(運転者/補助員の指定が特にないもの)
   */
  driverEntities() {
    return getDriverEntities(DriverType.Driver, this.assignableDrivers) || [];
  }

  /**
   * 運転者
   */
  operatorEntities() {
    return getDriverEntities(DriverType.Operator, this.assignableDrivers) || [];
  }

  /**
   * 補助員
   */
  helperEntities() {
    return getDriverEntities(DriverType.Helper, this.assignableDrivers) || [];
  }

  constructor(
    data: IOrderData,
    createdBy: UserEntity,
    updatedBy: UserEntity,
    client: AggregatedClientEntity,
    orderGroup: OrderGroupEntity,
    generationSite: AggregatedGenerationSiteEntity,
    orderAssignableDrivers: IAggregatedOrderAssignableDriver[],
    assignedDisposalSites: AggregatedDisposalSiteEntity[],
    assignableCars: AggregatedCarEntity[],
    assignableCarTypes: SimpleCarTypeEntity[],
    generationSiteTasks: AggregatedGenerationSiteTaskEntity[],
    irregularTasks: IrregularTaskEntity[],
    postponeOrderStatus: PostponeOrderStatus
  ) {
    super(
      data.id,
      data.id,
      data.plan,
      client,
      generationSite,
      data.preloadStatus,
      postponeOrderStatus,
      assignableCars,
      assignableCarTypes,
      data.fixedArrivalTime
    );
    this.code = data.code;
    this.serialNumber = data.serialNumber;
    this.durationAtGenerationSite = data.durationAtGenerationSite;
    this.routeCollectionAllowed = data.routeCollectionAllowed;

    const disposalSiteMap = mapEntity(assignedDisposalSites);
    const orderAssignedDisposalSitesAndType: IAggregatedOrderAssignedDisposalSitesAndType = {
      disposalSiteAssignmentType: data.assignedDisposalSitesAndType.disposalSiteAssignmentType,
      orderDisposalSites: data.assignedDisposalSitesAndType.orderDisposalSites.map((orderAssignedDisposalSite) => {
        const disposalSite = disposalSiteMap.getOrError(orderAssignedDisposalSite.disposalSite.id);
        return {
          disposalSite,
          durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance || disposalSite.durationAtEntrance,
          priority: orderAssignedDisposalSite.priority,
          disposablePeriodStart: orderAssignedDisposalSite.disposablePeriodStart,
          disposablePeriodEnd: orderAssignedDisposalSite.disposablePeriodEnd,
        };
      }),
    };
    this.assignAssignableDriversOnUnloadDate = data.assignAssignableDriversOnUnloadDate;
    this.orderAssignedDisposalSitesAndType = orderAssignedDisposalSitesAndType;
    this.driverAssignmentType = data.driverAssignmentType;
    this.assignableDrivers = orderAssignableDrivers;
    this.carNum = data.carNum;
    this.minAssignedDriverNum = data.minAssignedDriverNum;
    this.maxAssignedDriverNum = data.maxAssignedDriverNum;
    this.note = data.note;
    this.noteForAssignedDriver = data.noteForAssignedDriver;
    this.noteFromReservation = data.noteFromReservation;
    this.attachments = data.attachments || [];
    this.avoidHighways = data.avoidHighways;
    this.isFixedArrivalTimeReportNeeded = data.isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = data.marginTypeOfFixedArrivalTime;
    this.marginOfFixedArrivalTime = data.marginOfFixedArrivalTime;
    this.orderCheckItems = data.orderCheckItems;
    this.routingGroup = data.routingGroup;
    this.fixedDisplayOnReservation = data.fixedDisplayOnReservation;
    this.fixedDisplayOnReservationName = data.fixedDisplayOnReservationName;
    this.schedulingPriority = data.schedulingPriority;
    this.recurringSettings = data.recurringSettings;
    this.status = data.status;
    this.createdVia = data.createdVia;
    this.createdBy = createdBy;
    this.createdAt = data.createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = data.updatedAt;
    this.orderGroup = orderGroup;
    this.generationSiteTasks = generationSiteTasks;
    this.irregularTasks = irregularTasks;
    this.editScheduleDate = undefined;
    this.setGenerationSiteTasks(this.generationSiteTasks, this.irregularTasks);
  }

  update(
    data: IOrderData,
    createdBy: UserEntity,
    updatedBy: UserEntity,
    client: AggregatedClientEntity,
    orderGroup: OrderGroupEntity,
    generationSite: AggregatedGenerationSiteEntity,
    orderAssignableDrivers: IAggregatedOrderAssignableDriver[],
    assignedDisposalSites: AggregatedDisposalSiteEntity[],
    assignableCars: AggregatedCarEntity[],
    assignableCarTypes: SimpleCarTypeEntity[],
    generationSiteTasks: AggregatedGenerationSiteTaskEntity[],
    irregularTasks: IrregularTaskEntity[],
    postponeOrderStatus: PostponeOrderStatus
  ): void {
    if (this.id !== data.id) {
      throw new Error('invalid update of AggregatedOrderEntity');
    }
    this.code = data.code;
    this.code = data.code;
    this.plan = data.plan;
    this.serialNumber = data.serialNumber;
    this.durationAtGenerationSite = data.durationAtGenerationSite;
    this.routeCollectionAllowed = data.routeCollectionAllowed;
    this.preloadStatus = data.preloadStatus;

    const disposalSiteMap = mapEntity(assignedDisposalSites);
    const orderAssignedDisposalSitesAndType: IAggregatedOrderAssignedDisposalSitesAndType = {
      disposalSiteAssignmentType: data.assignedDisposalSitesAndType.disposalSiteAssignmentType,
      orderDisposalSites: data.assignedDisposalSitesAndType.orderDisposalSites.map((orderAssignedDisposalSite) => {
        const disposalSite = disposalSiteMap.getOrError(orderAssignedDisposalSite.disposalSite.id);
        return {
          disposalSite,
          durationAtEntrance: orderAssignedDisposalSite.durationAtEntrance || disposalSite.durationAtEntrance,
          priority: orderAssignedDisposalSite.priority,
          disposablePeriodStart: orderAssignedDisposalSite.disposablePeriodStart,
          disposablePeriodEnd: orderAssignedDisposalSite.disposablePeriodEnd,
        };
      }),
    };
    this.assignAssignableDriversOnUnloadDate = data.assignAssignableDriversOnUnloadDate;
    this.orderAssignedDisposalSitesAndType = orderAssignedDisposalSitesAndType;
    this.driverAssignmentType = data.driverAssignmentType;
    this.assignableDrivers = orderAssignableDrivers;
    this.carNum = data.carNum;
    this.minAssignedDriverNum = data.minAssignedDriverNum;
    this.maxAssignedDriverNum = data.maxAssignedDriverNum;
    this.note = data.note;
    this.noteForAssignedDriver = data.noteForAssignedDriver;
    this.noteFromReservation = data.noteFromReservation;
    this.attachments = data.attachments || [];
    this.avoidHighways = data.avoidHighways;
    this.fixedArrivalTime = data.fixedArrivalTime;
    this.isFixedArrivalTimeReportNeeded = data.isFixedArrivalTimeReportNeeded;
    this.marginTypeOfFixedArrivalTime = data.marginTypeOfFixedArrivalTime;
    this.marginOfFixedArrivalTime = data.marginOfFixedArrivalTime;
    this.orderCheckItems = data.orderCheckItems;
    this.routingGroup = data.routingGroup;
    this.fixedDisplayOnReservation = data.fixedDisplayOnReservation;
    this.fixedDisplayOnReservationName = data.fixedDisplayOnReservationName;
    this.schedulingPriority = data.schedulingPriority;
    this.recurringSettings = data.recurringSettings;
    this.status = data.status;
    this.createdVia = data.createdVia;
    this.createdBy = createdBy;
    this.createdAt = data.createdAt;
    this.updatedBy = updatedBy;
    this.updatedAt = data.updatedAt;
    this.client = client;
    this.orderGroup = orderGroup;
    this.generationSite = generationSite;
    this.assignableCars = assignableCars;
    this.assignableCarTypes = assignableCarTypes;
    this.generationSiteTasks = generationSiteTasks;
    this.irregularTasks = irregularTasks;
    this.postponeOrderStatus = postponeOrderStatus;
    this.editScheduleDate = undefined;
    this.setGenerationSiteTasks(this.generationSiteTasks, this.irregularTasks);
  }

  getIdenticalUpdateData(): IUpdateOrderData {
    return {
      id: this.id,
      plan: convertOrderPlanToOrderPlanInput(this.plan),
      orderGroupId: this.orderGroup.id,
      generationSiteId: this.generationSite.id,
      generationSiteTasks: undefined,
      irregularTasks: undefined,
      durationAtGenerationSite: this.durationAtGenerationSite,
      routeCollectionAllowed: this.routeCollectionAllowed,
      preloadStatus: this.preloadStatus,
      assignAssignableDriversOnUnloadDate: this.assignAssignableDriversOnUnloadDate,
      assignedDisposalSitesAndType: {
        orderDisposalSites: this.orderAssignedDisposalSitesAndType.orderDisposalSites.map((orderDisposalSite) => {
          return {
            disposalSiteId: orderDisposalSite.disposalSite.id,
            priority: orderDisposalSite.priority,
            durationAtEntrance: orderDisposalSite.durationAtEntrance,
            disposablePeriodStart: orderDisposalSite.disposablePeriodStart,
            disposablePeriodEnd: orderDisposalSite.disposablePeriodEnd,
          };
        }),
        disposalSiteAssignmentType: this.orderAssignedDisposalSitesAndType.disposalSiteAssignmentType,
      },
      assignableDriversAndNum: {
        minAssignedDriverNum: this.minAssignedDriverNum,
        maxAssignedDriverNum: this.maxAssignedDriverNum,
        driverAssignmentType: this.driverAssignmentType,
        assignableDrivers: this.assignableDrivers.map((assignableDriver) => {
          return { driverId: assignableDriver.driver.id, driverType: assignableDriver.driverType };
        }),
      },
      assignableCarIds: ids(this.assignableCars),
      assignableCarTypeIds: ids(this.assignableCarTypes),
      carNum: this.carNum,
      note: this.note,
      noteForAssignedDriver: undefined,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
      avoidHighways: this.avoidHighways,
      fixedArrivalTime: this.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: this.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: this.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: this.marginOfFixedArrivalTime,
      checkItemIds: this.orderCheckItems.map((item) => item.checkItem.id),
      routingGroup: this.routingGroup,
      fixedDisplayOnReservation: this.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: this.fixedDisplayOnReservationName,
      schedulingPriority: this.schedulingPriority,
      includeFollowingRecurringOrders: undefined,
      status: this.status,
    };
  }
}

const logger = createLogger(`AggregatedOrderEntity`);
