import { Store } from '~/framework/domain/store';
import { ServerApiManager } from '~/framework/server-api/serverApiManager';
import { Maybe, PersistentId } from '~/framework/typeAliases';
import { AggregatedOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { ICreateOrderData, order$createOrdersSymbol } from '~/framework/server-api/schedule/order/createOrders';
import { IUpdateOrderData, order$updateOrdersSymbol } from '~/framework/server-api/schedule/order/updateOrders';
import { order$ordersByIdsSymbol } from '~/framework/server-api/schedule/order/ordersByIds';
import { order$ordersByDateSymbol } from '~/framework/server-api/schedule/order/ordersByDate';
import { OrderService, ValidateTaskType } from '~/framework/domain/schedule/order/orderService';
import { order$cancelOrderSymbol } from '~/framework/server-api/schedule/order/cancelOrder';
import { order$activateOrderSymbol } from '~/framework/server-api/schedule/order/activateOrder';
import {
  order$postponeOrdersSymbol,
  IPostponeData,
  PostponeOrderResultTypes,
} from '~/framework/server-api/schedule/order/postponeOrders';
import { OrderStatus } from '~/framework/domain/typeAliases';
import { RawScheduleJsonObject } from '~/framework/server-api/schedule/schedule/schedule';
import { order$validateOrdersSymbol } from '~/framework/server-api/schedule/order/validateOrders';
import { taskType$getAllSymbol } from '~/framework/server-api/masters/taskType';
import { IllegalArgumentException } from '~/framework/core/exception';

import { retry } from '~/framework/core/retry/retry';
import { GraphQLResultConsistencyException } from '~/framework/port.adapter/server-api/graphqlApiBase';
import { ExponentialBackoffStrategy } from '~/framework/core/retry/exponentialBackoffStrategy';
import { RawRouteJsonObject } from '~/graphql/custom-scalars/scheduleJsonObjectTypes';
import { IOrderDefault } from '~/framework/server-api/schedule/order/order-default/orderDefault';
import { orderDefault$orderDefaultByGenerationSiteId } from '~/framework/server-api/schedule/order/order-default/orderDefaultByGenerationSiteId';
import { ILatestOrdersCondition, order$latestOrders } from '~/framework/server-api/schedule/order/latestOrders';
import { IRoutableOrdersCondition, order$routableOrders } from '~/framework/server-api/schedule/order/routableOrders';
import { convertOrderPlanToOrderPlanInput } from '~/framework/domain/schedule/order/orderUtils';
import { IGenerationSiteTaskItem } from '~/components/panels/schedule/r-order-form/r-generation-site-task-field/generationSiteTaskItem';
import { order$updateOrderCheckItemSymbol } from '~/framework/server-api/schedule/order/updateOrderCheckItem';
import { AggregatedOrderMapper } from '~/framework/domain/schedule/order/aggregatedOrderMapper';
import { ids } from '~/framework/core/entity';
export const orderSymbol = Symbol('order');

export class OrderApplicationService {
  [orderSymbol] = undefined;
  private readonly store: Store;
  private readonly serverApis: ServerApiManager;
  private orderMapper: AggregatedOrderMapper;

  constructor(store: Store, serverApis: ServerApiManager) {
    this.store = store;
    this.serverApis = serverApis;
    this.orderMapper = new AggregatedOrderMapper(
      store.schedule.aggregatedOrder,
      store.masters.aggregatedGenerationSite,
      store.masters.aggregatedClient,
      store.masters.user,
      store.masters.collectablePeriodTemplate,
      store.masters.orderGroup,
      store.masters.aggregatedDisposalSite,
      store.masters.aggregatedWasteType,
      store.masters.aggregatedCar,
      store.masters.aggregatedBaseSite,
      store.masters.aggregatedDriver,
      store.masters.driverAttendanceTemplate,
      store.masters.jwnetWasteMaster,
      store.masters.aggregatedContainerType,
      store.masters.packingStyle
    );
  }

  async create(data: ICreateOrderData[]): Promise<AggregatedOrderEntity[]> {
    const service = new OrderService();
    for (const singleData of data) {
      service.validateTasks(ValidateTaskType.Create, singleData.generationSiteTasks, singleData.irregularTasks);
      service.validateAssignedDisposalSiteIds(singleData, await this.serverApis.get(taskType$getAllSymbol).getAll());
    }

    const order$createOrdersApi = this.serverApis.get(order$createOrdersSymbol);
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    // 取り直すのは無駄なのだが、こうしてしまった方が楽なので
    // 将来的に create の結果を Order の型にできればこの必要はない
    const createResult = await order$createOrdersApi.createOrders(data);
    const ids = createResult.map((item) => item.id);
    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds(ids);
      return this.orderMapper.map(data);
    });
    return entities;
  }

  async update(data: IUpdateOrderData): Promise<AggregatedOrderEntity> {
    const service = new OrderService();
    service.validateTasks(ValidateTaskType.Update, data.generationSiteTasks, data.irregularTasks);
    service.validateAssignedDisposalSiteIds(data, await this.serverApis.get(taskType$getAllSymbol).getAll());
    const order$updateOrdersApi = this.serverApis.get(order$updateOrdersSymbol);
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);

    if (data.plan === undefined) {
      throw new Error('date.plan is undefined');
    }
    const [createResult] = await order$updateOrdersApi.updateOrders([data]);
    const id = createResult.id;
    const [entity] = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds([id]);
      return this.orderMapper.map(data);
    });
    return entity;
  }

  async cancel(id: PersistentId): Promise<AggregatedOrderEntity> {
    const order$cancelOrderApi = this.serverApis.get(order$cancelOrderSymbol);
    const data = await order$cancelOrderApi.cancelOrder(id);
    return this.orderMapper.mapSingle(data);
  }

  async activate(id: PersistentId): Promise<AggregatedOrderEntity> {
    const order$activateOrderApi = this.serverApis.get(order$activateOrderSymbol);
    const data = await order$activateOrderApi.activateOrder(id);
    return this.orderMapper.mapSingle(data);
  }

  async delete(id: PersistentId, includeFollowingRecurringOrders: Maybe<boolean>): Promise<AggregatedOrderEntity> {
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    const order$updateOrdersApi = this.serverApis.get(order$updateOrdersSymbol);

    const [data] = await order$ordersByIdsApi.ordersByIds([id]);
    if (!data.recurringSettings && includeFollowingRecurringOrders !== undefined) {
      throw new IllegalArgumentException(`includeFollowingRecurringOrders parameter cannot be given to one-shot order`);
    }

    // status を deleted に書き換える事で削除されたという事にしている
    data.status = OrderStatus.Deleted;

    // __typename入っていれば削除する
    delete data.plan?.__typename;
    delete data.plan?.fixed?.__typename;
    data.plan?.candidateDates?.forEach((candidateDate) => delete candidateDate.__typename);
    delete data.routingGroup?.__typename;

    const updateData: IUpdateOrderData = {
      id: data.id,
      plan: convertOrderPlanToOrderPlanInput(data.plan),
      orderGroupId: data.orderGroup.id,
      generationSiteId: data.generationSite.id,
      generationSiteTasks: undefined, // undefined = 更新しない
      irregularTasks: undefined, // undefined = 更新しない
      durationAtGenerationSite: data.durationAtGenerationSite,
      routeCollectionAllowed: data.routeCollectionAllowed,
      preloadStatus: data.preloadStatus,
      assignAssignableDriversOnUnloadDate: data.assignAssignableDriversOnUnloadDate,
      assignedDisposalSitesAndType: {
        orderDisposalSites: data.assignedDisposalSitesAndType.orderDisposalSites.map((assignedDisposalSite) => {
          return {
            disposalSiteId: assignedDisposalSite.disposalSite.id,
            // NOTE: 本来はFEがデフォルトの値を計算しているが、削除は api から orderData を直接取得してそのまま update をかけるため
            // 特に意味はないが undefined の場合は 0 を設定している
            durationAtEntrance: assignedDisposalSite.durationAtEntrance ? assignedDisposalSite.durationAtEntrance : 0,
            priority: assignedDisposalSite.priority,
            disposablePeriodStart: assignedDisposalSite.disposablePeriodStart,
            disposablePeriodEnd: assignedDisposalSite.disposablePeriodEnd,
          };
        }),
        disposalSiteAssignmentType: data.assignedDisposalSitesAndType.disposalSiteAssignmentType,
      },
      assignableDriversAndNum: {
        minAssignedDriverNum: data.minAssignedDriverNum,
        maxAssignedDriverNum: data.maxAssignedDriverNum,
        driverAssignmentType: data.driverAssignmentType,
        // NOTE: remove __typeName
        assignableDrivers: data.assignableDrivers.map((assignableDriver) => {
          return {
            driverType: assignableDriver.driverType,
            driverId: assignableDriver.driver.id,
          };
        }),
      },
      assignableCarIds: ids(data.assignableCars),
      assignableCarTypeIds: ids(data.assignableCarTypes),
      carNum: data.carNum,
      note: data.note,
      noteForAssignedDriver: data.noteForAssignedDriver,
      attachmentsToAdd: [],
      attachmentsToRemove: [],
      avoidHighways: data.avoidHighways,
      fixedArrivalTime: data.fixedArrivalTime,
      isFixedArrivalTimeReportNeeded: data.isFixedArrivalTimeReportNeeded,
      marginTypeOfFixedArrivalTime: data.marginTypeOfFixedArrivalTime,
      marginOfFixedArrivalTime: data.marginOfFixedArrivalTime,
      checkItemIds: data.orderCheckItems.map((item) => item.checkItem.id),
      routingGroup: data.routingGroup,
      fixedDisplayOnReservation: data.fixedDisplayOnReservation,
      fixedDisplayOnReservationName: data.fixedDisplayOnReservationName,
      schedulingPriority: data.schedulingPriority,
      includeFollowingRecurringOrders,
      status: data.status,
    };
    await order$updateOrdersApi.updateOrders([updateData]);
    return this.orderMapper.mapSingle(data);
  }

  async getByDate(date: Date): Promise<AggregatedOrderEntity[]> {
    const order$ordersByDateApi = this.serverApis.get(order$ordersByDateSymbol);
    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByDateApi.ordersByDate(date);
      return this.orderMapper.map(data);
    });

    return entities;
  }

  async getById(id: PersistentId): Promise<AggregatedOrderEntity> {
    const [entity] = await this.getByIds([id]);
    return entity;
  }

  async getByIds(ids: PersistentId[]): Promise<AggregatedOrderEntity[]> {
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds(ids);
      return this.orderMapper.map(data);
    });
    return entities;
  }

  async getLatestOrders(condition: ILatestOrdersCondition): Promise<AggregatedOrderEntity[]> {
    const order$latestOrdersApi = this.serverApis.get(order$latestOrders);

    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$latestOrdersApi.latestOrders(condition);
      return this.orderMapper.map(data);
    });
    return entities;
  }

  async getRoutableOrders(condition: IRoutableOrdersCondition): Promise<AggregatedOrderEntity[]> {
    const order$routableOrdersApi = this.serverApis.get(order$routableOrders);

    const entities = await this.getOrdersWithRetry(async () => {
      const data = await order$routableOrdersApi.routableOrders(condition);
      return this.orderMapper.map(data);
    });

    return entities;
  }

  async postponeOrders(data: IPostponeData[]): Promise<PostponeOrderResultTypes[]> {
    const order$ordersByIdsApi = this.serverApis.get(order$ordersByIdsSymbol);
    const order$postponeOrdersApi = this.serverApis.get(order$postponeOrdersSymbol);

    const results = await order$postponeOrdersApi.postponeOrders(data);
    // postponeOrdersでエラーが出ていないOrderをUpdateする
    const ids: PersistentId[] = [];
    results.forEach((result) => {
      if (result.__typename === 'Order') {
        ids.push(result.id);
      }
    });
    await this.getOrdersWithRetry(async () => {
      const data = await order$ordersByIdsApi.ordersByIds(ids);
      return this.orderMapper.map(data);
    });
    return results;
  }

  async updateOrderCheckItem(orderCheckItemId: string, checked: boolean): Promise<AggregatedOrderEntity> {
    const order$updateOrderCheckItemApi = this.serverApis.get(order$updateOrderCheckItemSymbol);

    const result = await order$updateOrderCheckItemApi.updateOrderCheckItem(orderCheckItemId, checked);
    return this.orderMapper.mapSingle(result);
  }

  async validateOrder(order: ICreateOrderData): Promise<RawScheduleJsonObject<RawRouteJsonObject>> {
    const validateOrderApi = this.serverApis.get(order$validateOrdersSymbol);
    const validateOrderData: ICreateOrderData = {
      ...order,
      attachmentsToAdd: [],
    };
    const [data] = await validateOrderApi.validateOrders([validateOrderData]);
    return data;
  }

  async getOrderDefaultByGenerationSiteId(id: PersistentId): Promise<IOrderDefault> {
    const order$orderDefaultByGenerationSiteIdApi = this.serverApis.get(orderDefault$orderDefaultByGenerationSiteId);
    const result = await order$orderDefaultByGenerationSiteIdApi.orderDefaultByGenerationSiteId(id);
    return result;
  }

  getGenerationSiteTaskKey(generationSiteTaskItem: IGenerationSiteTaskItem): string {
    return `${generationSiteTaskItem.taskType?.id}-${generationSiteTaskItem.wasteTypeId}-${generationSiteTaskItem.containerTypeId}`;
  }

  /**
   * 受注情報を取得する様な API 操作を func として渡し、現場タスクなどの取得エラーが起きた場合には
   * リトライを行って再取得を試みる。受注情報を取得した際に GenerationSiteTasksByIds の様な API を
   * 多段階で呼ぶ可能性があるが、API の呼び出し間隔によっては最初に受注情報を取得した際に得られた
   * generationSiteTaskIds の中身が実は他の誰かによって更新されたために削除されたりして
   * GenerationSiteTasksByIds で取得できない事がある。しかしサーバー側で受注情報自体が壊れている訳ではなく
   * 再取得すれば取得できるので、ここで retry している。
   *
   * 1.(受注情報の取得) -> 2.(誰かが Order を更新して generationSiteTaskIds の指すタスクがなくなる) ->
   *   3.(GenerationSiteTasksByIds がコケる) -> 4.(自動で 1 からリトライ)
   *
   * 根本的には Order の中の generationTaskIds をネストして Type 自体を返せば解決する。
   *
   * @param func 必要に応じて複数回呼ばれる可能性がある事に注意する事
   * @private
   */
  private async getOrdersWithRetry<T>(func: () => Promise<T>): Promise<T> {
    return await retry(func, this.isGenerationSiteTaskNotFoundException, new ExponentialBackoffStrategy(500, 3));
  }

  private isGenerationSiteTaskNotFoundException(error: Error): boolean {
    return (
      error instanceof GraphQLResultConsistencyException &&
      (error.typename === 'GenerationSiteTasksByIds' || error.typename === 'IrregularTasksByIds')
    );
  }
}
