
import Vue from 'vue';
import RAttendanceDialog from './result-dialogs/RAttendanceDialog.vue';
import RInconsistentDialog from './result-dialogs/RInconsistentDialog.vue';
import ROtherOrderDialog from './result-dialogs/ROtherOrderDialog.vue';
import RUnstableResultDialog from './result-dialogs/RUnstableResultDialog.vue';
import { Maybe, Seconds } from '~/framework/typeAliases';
import {
  AbortedError,
  CancelledByUserError,
  CancelledError,
} from '~/framework/application/schedule/order/order-acceptance-check/errors';
import { Path } from '~/framework/constants';
import {
  IInconsistencyError,
  IInfeasibilityOfAttendanceError,
  IInfeasibilityOfOptimizationError,
  IInfeasibilityOfPrecheckError,
  INoAttendancesError,
  IViolatesMultipleCheckConditionError,
} from '~/framework/application/schedule/order/order-acceptance-check/resultErrors';
import { Inconsistency } from '~/pages/schedule/inconsistency';
import { formatDateToString } from '~/framework/services/date/date';
import {
  OrderAcceptanceCheckApplicationService,
  IOrderAcceptanceCheckData,
  orderAcceptanceCheckSymbol,
} from '~/framework/application/schedule/order/order-acceptance-check/orderAcceptanceCheckApplicationService';
import {
  ScheduleApplicationService,
  scheduleSymbol,
} from '~/framework/application/schedule/schedule/scheduleApplicationService';
import { Infeasibility } from '~/pages/schedule/infeasibility';
import { getBasePlanFromOrderPlanInput } from '~/framework/domain/schedule/order/orderUtils';
import { OrderCandidateDate, OrderFixedPlan } from '~/graphql/graphQLServerApi';

type DataType = {
  orderAcceptanceCheckApplicationService: OrderAcceptanceCheckApplicationService;
  scheduleApplicationService: ScheduleApplicationService;
  input: Maybe<IOrderAcceptanceCheckData>;
  durationToFinish: Maybe<Seconds>;
  startedAt: Maybe<Date>;
  progress: Maybe<number>;
  timerHandle: Maybe<number>;
  isChecking: boolean;
  ordersNum: Maybe<number>;
  estimatedFinishAt: Maybe<Date>;
  isDialogActive: {
    attendance: boolean;
    inconsistent: boolean;
    otherOrder: boolean;
    unstableResult: boolean;
  };
  inconsistencies: Maybe<Inconsistency[]>;
  infeasibilities: Maybe<Infeasibility[]>;
};

enum EventTypes {
  /**
   * チェック状態になった事を認識するために使う
   */
  UpdateIsChecking = 'update:is-checking',
  /**
   * Order, OrderOrMaster の infeasibilities がこのコンポーネントに input されたという意図
   * フォームで使われるので合わせた形
   */
  InputPrecheckInfeasibilities = 'input:precheck-infeasibilities',
  /**
   * 受注情報を登録したい場合
   */
  ClickRegisterOrder = 'click:register-order',
}

/**
 * window に依存するのも微妙なのだが、かと言って NodeJS に依存するのも微妙なので
 * interval 系は window を指定している
 */
export default Vue.extend({
  name: 'ROrderAcceptanceCheck',
  components: {
    RAttendanceDialog,
    RInconsistentDialog,
    ROtherOrderDialog,
    RUnstableResultDialog,
  },
  data(): DataType {
    const orderAcceptanceCheckApplicationService = this.$context.applications.get(orderAcceptanceCheckSymbol);
    orderAcceptanceCheckApplicationService.addPresenter({
      updateStatus: (isChecking: boolean, estimatedFinishAt: Maybe<Date>, ordersNum: Maybe<number>) => {
        this.$data.isChecking = isChecking;
        this.$data.estimatedFinishAt = estimatedFinishAt;
        this.$data.ordersNum = ordersNum;
      },
    });
    const scheduleApplicationService = this.$context.applications.get(scheduleSymbol);
    return {
      orderAcceptanceCheckApplicationService,
      scheduleApplicationService,
      input: undefined,
      durationToFinish: undefined,
      startedAt: undefined,
      progress: undefined,
      timerHandle: undefined,
      isChecking: false,
      ordersNum: undefined,
      estimatedFinishAt: undefined,
      isDialogActive: {
        attendance: false,
        inconsistent: false,
        otherOrder: false,
        unstableResult: false,
      },
      inconsistencies: undefined,
      infeasibilities: undefined,
    };
  },
  computed: {
    plan(): Maybe<OrderFixedPlan | OrderCandidateDate> {
      if (this.input === undefined) return;

      return getBasePlanFromOrderPlanInput(this.input.plan);
    },
    date(): Maybe<Date> {
      if (this.plan === undefined) return;
      return this.plan.date;
    },
    isWaiting(): boolean {
      // チェック中ではあるが estimatedFinishAt がないという事は waiting
      // status を取ってもいいのだが、若干冗長になりそうな気がしたので
      return this.isChecking && this.estimatedFinishAt === undefined;
    },
    isRunning(): boolean {
      // チェック中で estimatedFinishAt があるという事は running
      // status を取ってもいいのだが、若干冗長になりそうな気がしたので
      return this.isChecking && this.estimatedFinishAt !== undefined;
    },
    isIndeterminate(): boolean {
      // あと0秒でプログレスが止まると微妙なのでその場合は indeterminate にしておく
      if (this.isWaiting) return true;
      else return this.durationToFinish !== undefined && this.durationToFinish === 0;
    },
    isCancellable(): boolean {
      return this.isRunning;
    },
  },
  watch: {
    estimatedFinishAt(value: Maybe<Date>, oldValue: Maybe<Date>): void {
      if (oldValue === undefined && value !== undefined) {
        this.startedAt = new Date();
      } else if (oldValue !== undefined && value === undefined) {
        this.startedAt = undefined;
      } else if (value !== undefined && oldValue !== undefined && value.getTime() !== oldValue.getTime()) {
        this.startedAt = new Date();
      }

      this.update();
    },
    isChecking(value: boolean, oldValue: boolean): void {
      if (value !== oldValue) this.$emit(EventTypes.UpdateIsChecking, value);
    },
  },
  mounted() {
    this.timerHandle = window.setInterval(this.update, 100);
  },
  beforeDestroy() {
    if (this.timerHandle !== undefined) window.clearInterval(this.timerHandle);
    if (this.isChecking) this.orderAcceptanceCheckApplicationService.cancel();
  },
  methods: {
    /**
     * @public
     */
    async checkOrderAcceptance(input: IOrderAcceptanceCheckData): Promise<void> {
      this.input = input;
      try {
        const acceptanceCheckResult = await this.orderAcceptanceCheckApplicationService.checkOrderAcceptance(input);

        if (acceptanceCheckResult.isAcceptable.object && acceptanceCheckResult.isAcceptable.others) {
          this.$context.snackbar.success('受注できます', { timeout: 0 });
        } else if (acceptanceCheckResult.error !== undefined) {
          await acceptanceCheckResult.error.accept(this);
        }
      } catch (e) {
        if (e instanceof CancelledByUserError) {
          console.log(e);
        } else if (e instanceof CancelledError) {
          this.$context.snackbar.error('エラーにより瞬間チェックが行えませんでした。');
        } else if (e instanceof AbortedError) {
          this.$context.snackbar.error('瞬間チェックが想定よりも時間がかかっているため、キャンセルされました。');
        } else {
          throw e;
        }
      }
    },
    secsToDuration(value: Seconds): string {
      const minutes = Math.floor(value / 60);
      const seconds = Math.floor(value % 60);
      if (minutes === 0) return `${seconds}秒`;
      else return `${minutes}分${seconds}秒`;
    },
    update(): void {
      if (this.estimatedFinishAt !== undefined && this.startedAt !== undefined) {
        // 開始時から終了までどれくらい時間がかかるか（の予測、msec）
        const durationToFinish = this.estimatedFinishAt.getTime() - this.startedAt.getTime();
        // 現在時刻からあとどれくらい時間がかかるか（の予測、msec）
        const remainingDuration = Math.max(this.estimatedFinishAt.getTime() - new Date().getTime(), 0);
        this.durationToFinish = remainingDuration / 1000;
        this.progress = 100 - (remainingDuration / durationToFinish) * 100;
        // あと0秒になった時に progress が 100 だと indeterminate の表示が微妙なので 0 にしておく
        if (this.durationToFinish !== undefined && this.durationToFinish === 0) this.progress = 0;
      }
    },
    onClickCancel(): void {
      this.orderAcceptanceCheckApplicationService.cancel();
      this.reset();
    },
    reset(): void {
      this.estimatedFinishAt = undefined;
      this.ordersNum = undefined;
      this.durationToFinish = undefined;
      this.startedAt = undefined;
      this.progress = undefined;
    },
    onCloseAttendanceDialog(): void {
      this.isDialogActive.attendance = false;
    },
    async onEditAttendanceOfAttendanceDialog(): Promise<void> {
      if (this.input === undefined) throw new Error(`input is undefined!`);
      if (this.date === undefined) throw new Error(`date is undefined!`);
      this.isDialogActive.attendance = false;
      await this.$router.push({
        path: Path.masters.driver,
        hash: 'driver-attendances',
        query: { date: formatDateToString(this.date) },
      });
    },
    async onClickRinLinkOfAttendanceDialog(_infeasibility: Infeasibility, path: string): Promise<void> {
      if (this.input === undefined) throw new Error(`input is undefined!`);
      this.isDialogActive.attendance = false;
      await this.$router.push({ path });
    },
    onRegisterOrderOfAttendanceDialog(): void {
      this.isDialogActive.attendance = false;
      this.$emit(EventTypes.ClickRegisterOrder);
    },
    onCloseInconsistentDialog(): void {
      this.inconsistencies = undefined;
      this.isDialogActive.inconsistent = false;
    },
    async onCheckScheduleOfInconsistentDialog(): Promise<void> {
      if (this.input === undefined) throw new Error(`input is undefined!`);
      if (this.date === undefined) throw new Error(`date is undefined!`);
      this.inconsistencies = undefined;
      this.isDialogActive.inconsistent = false;
      await this.$router.push({
        path: Path.schedule.index,
        hash: 'errors',
        query: { date: formatDateToString(this.date), orderGroupId: this.input.orderGroupId },
        replace: true,
      });
    },

    onCloseOtherOrderDialog(): void {
      this.isDialogActive.otherOrder = false;
    },
    async onCreateScheduleOfOtherOrderDialog(): Promise<void> {
      if (this.input === undefined) throw new Error(`input is undefined!`);
      if (this.date === undefined) throw new Error(`date is undefined!`);
      this.isDialogActive.otherOrder = false;
      await this.$context.events.createScheduleEvent.emit({
        date: this.date,
        orderGroupId: this.input.orderGroupId,
      });
    },

    onCloseUnstableResultDialog(): void {
      this.isDialogActive.unstableResult = false;
    },
    async onCreateScheduleOfUnstableResultDialog(): Promise<void> {
      if (this.input === undefined) throw new Error(`input is undefined!`);
      this.isDialogActive.unstableResult = false;
      await this.$context.events.createScheduleEvent.emit({
        date: this.date,
        orderGroupId: this.input.orderGroupId,
      });
    },

    // IErrorProcessor
    processNoAttendanceError(error: INoAttendancesError): Promise<void> {
      console.error(error);
      this.$context.snackbar.error(
        '勤怠が登録されていないため、瞬間チェックできませんでした。\n該当の日の勤怠を登録して下さい。'
      );
      return Promise.resolve();
    },
    async processViolatesMultipleCheckConditionError(error: IViolatesMultipleCheckConditionError): Promise<void> {
      // もし既にチェック中の ID が分かっている場合はその情報を取る
      let secsToFinish;
      if (error.checking !== undefined) {
        const checkingEntity = await this.orderAcceptanceCheckApplicationService.getById(error.checking);
        secsToFinish =
          checkingEntity.estimatedFinishAt === undefined
            ? undefined
            : Math.max((checkingEntity.estimatedFinishAt.getTime() - new Date().getTime()) / 1000, 0);
      }
      const message =
        secsToFinish !== undefined
          ? `瞬間チェックを既に実行中のため、実行できませんでした。\n${this.secsToDuration(
              secsToFinish
            )}ほど待ってもう一度実行して下さい。`
          : `瞬間チェックを既に実行中のため、実行できませんでした。\nしばらく待ってもう一度実行して下さい。`;
      this.$context.snackbar.error(message);
    },
    processInfeasibilityOfPrecheckError(error: IInfeasibilityOfPrecheckError): Promise<void> {
      if (error.hasError.object) {
        // このコンポーネントでは表示できないので、上位に任せる
        this.$emit(EventTypes.InputPrecheckInfeasibilities, error.infeasibilities.object);
      } else {
        this.isDialogActive.otherOrder = true;
      }
      return Promise.resolve();
    },
    async processInconsistencyError(error: IInconsistencyError): Promise<void> {
      this.inconsistencies = await this.orderAcceptanceCheckApplicationService.getOrderAcceptanceCheckInconsistencies(
        error.inconsistencies
      );
      this.isDialogActive.inconsistent = true;
    },
    async processInfeasibilityOfAttendanceError(error: IInfeasibilityOfAttendanceError): Promise<void> {
      if (this.input === undefined) throw new Error(`input is undefined!`);

      if (error.hasError.object) {
        this.infeasibilities = await this.orderAcceptanceCheckApplicationService.getOrderAcceptanceCheckInfeasibilities(
          error.infeasibilities.object,
          this.input
        );
        this.isDialogActive.attendance = true;
      } else {
        this.isDialogActive.otherOrder = true;
      }
    },
    processInfeasibilityOfOptimizationError(error: IInfeasibilityOfOptimizationError): Promise<void> {
      if (error.hasError.object === false && error.hasError.others) {
        // 今は受注できるがそのうちできなくなるやつ
        this.isDialogActive.unstableResult = true;
      } else {
        this.$context.snackbar.error('受注できません');
      }
      return Promise.resolve();
    },
  },
});
