
import Vue, { PropType } from 'vue';
import { DatePickerAllowedDatesFunction } from 'vuetify';
import { compareAsc } from 'date-fns';
import { PreloadStatus } from '@/graphql/graphQLServerApi';
import { Maybe, PersistentId, Seconds, CssStyles } from '~/framework/typeAliases';
import RCollectablePeriodInput from '~/components/panels/schedule/r-order-form/RCollectablePeriodInput.vue';
import {
  DesignatedTimePeriodOption,
  DesignatedTimePeriodOptionId,
  DistinctTimeOptionId,
  ICollectablePeriodTemplateOption,
} from '~/framework/view-models/collectablePeriodTemplateOption';
import { formatDateForField, parseStringToDate } from '~/framework/services/date/date';
import { IBusinessDaysService } from '~/framework/services/business-days/businessDaysService';
import { CollectablePeriodTemplateEntity } from '~/framework/domain/masters/collectable-period-template/collectablePeriodTemplateEntity';

type DataType = {
  dateValue: Date;
  unloadDateValue: Maybe<Date>;
  collectableDistinctTimeValue: Maybe<Seconds>;
  collectablePeriodStartValue: Maybe<Seconds>;
  collectablePeriodEndValue: Maybe<Seconds>;
  collectablePeriodTemplateIdValue: Maybe<PersistentId>;
  collectablePeriodTemplateNameValue: Maybe<String>;
  PreloadStatus: typeof PreloadStatus;
  collectableDistinctTimeErrorMessages: string[];
  collectablePeriodStartErrorMessages: string[];
  collectablePeriodEndErrorMessages: string[];
};

enum EventTypes {
  UpdateDate = 'update:date',
  UpdateUnloadDate = 'update:unload-date',
  ClickUnloadDate = 'click:unload-date',
  UpdateCollectablePeriodTemplateId = 'update:collectable-period-template-id',
  UpdateCollectablePeriodTemplateName = 'update:collectable-period-template-name',
  UpdateCollectableDistinctTime = 'update:collectable-distinct-time',
  UpdateCollectablePeriodStart = 'update:collectable-period-start',
  UpdateCollectablePeriodEnd = 'update:collectable-period-end',
}

export default Vue.extend({
  name: 'RDateCollectablePeriodInput',
  components: {
    RCollectablePeriodInput,
  },
  props: {
    label: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    isCollectableTimeDistinct: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectablePeriodTemplateDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectableDistinctTimeDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectablePeriodStartDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isCollectablePeriodEndDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    collectablePeriodTemplates: {
      type: Array as PropType<ICollectablePeriodTemplateOption[]>,
      required: true,
    },
    collectablePeriodTemplateMap: {
      type: Map as PropType<Map<PersistentId, CollectablePeriodTemplateEntity>>,
      required: true,
    },
    date: {
      type: Date as PropType<Date>,
      required: false,
      default: undefined,
    },
    unloadDate: {
      type: Date as PropType<Date>,
      required: false,
      default: undefined,
    },
    collectableDistinctTime: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    collectablePeriodStart: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    collectablePeriodEnd: {
      type: Number as PropType<Maybe<Seconds>>,
      required: false,
      default: undefined,
    },
    collectablePeriodTemplateId: {
      type: String as PropType<Maybe<PersistentId>>,
      required: false,
      default: undefined,
    },
    collectablePeriodTemplateName: {
      type: String as PropType<Maybe<string>>,
      required: false,
      default: undefined,
    },
    isDateDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isUnloadDateDisabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isUnloadDateReadOnly: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    allowedDates: {
      type: Function as PropType<DatePickerAllowedDatesFunction>,
      required: false,
      default() {
        // allow all dates by default
        return true;
      },
    },
    preloadStatus: {
      type: String as PropType<PreloadStatus>,
      required: false,
      default: PreloadStatus.NotAllowed,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      required: false,
      default() {
        return [];
      },
    },
    businessDaysService: {
      type: Object as PropType<IBusinessDaysService>,
      required: false,
      default: undefined,
    },
    // NOTE: 到着時間テンプレートの form のデザインを調整できる
    templateStyles: {
      type: Object as PropType<CssStyles>,
      required: false,
      default: () => {
        return undefined;
      },
    },
  },
  data(): DataType {
    return {
      dateValue: this.date,
      unloadDateValue: this.unloadDate,
      collectableDistinctTimeValue: this.collectableDistinctTime,
      collectablePeriodStartValue: this.collectablePeriodStart,
      collectablePeriodEndValue: this.collectablePeriodEnd,
      collectablePeriodTemplateIdValue: this.collectablePeriodTemplateId,
      collectablePeriodTemplateNameValue: this.collectablePeriodTemplateName,
      PreloadStatus,
      collectableDistinctTimeErrorMessages: [],
      collectablePeriodStartErrorMessages: [],
      collectablePeriodEndErrorMessages: [],
    };
  },
  computed: {
    unloadDateClasses() {
      return [
        'r-date-collectable-period-input__unload-date',
        {
          'r-date-collectable-period-input__unload-date--disabled': this.isUnloadDateDisabled,
        },
      ];
    },
    selectedCollectablePeriodTemplateEntity(): Maybe<CollectablePeriodTemplateEntity> {
      if (this.collectablePeriodTemplateId === undefined) return;
      if (
        this.collectablePeriodTemplateId === DistinctTimeOptionId ||
        this.collectablePeriodTemplateId === DesignatedTimePeriodOptionId
      )
        return;

      return this.collectablePeriodTemplateMap.get(this.collectablePeriodTemplateId);
    },
  },
  watch: {
    dateValue() {
      this.checkDate();
    },
    date(newValue: Date) {
      this.dateValue = newValue;
    },
    unloadDate(newValue: Date) {
      this.unloadDateValue = newValue;
    },
    collectableDistinctTime(newValue: Maybe<number>) {
      this.collectableDistinctTimeValue = newValue;
    },
    collectablePeriodStart(newValue: Maybe<number>) {
      this.collectablePeriodStartValue = newValue;
    },
    collectablePeriodEnd(newValue: Maybe<number>) {
      this.collectablePeriodEndValue = newValue;
    },
    collectablePeriodTemplateId(newValue: Maybe<string>) {
      this.collectablePeriodTemplateIdValue = newValue;
    },
    collectablePeriodTemplateName(newValue: Maybe<string>) {
      this.collectablePeriodTemplateNameValue = newValue;
    },
  },
  methods: {
    formatDateForField,
    onChangeDate(value: Date) {
      this.$emit(EventTypes.UpdateDate, value);
    },
    onChangeUnloadDate(value: Date) {
      this.$emit(EventTypes.UpdateUnloadDate, value);
    },
    onClickUnloadDate(): void {
      this.$emit(EventTypes.ClickUnloadDate);
    },
    onChangeCollectablePeriodTemplate(args: any): void {
      const collectablePeriodTemplateId = this.collectablePeriodTemplateIdValue;
      if (collectablePeriodTemplateId !== undefined) {
        this.$emit(EventTypes.UpdateCollectablePeriodTemplateId, args);
        const collectablePeriodTemplate = this.collectablePeriodTemplateMap.getOrError(collectablePeriodTemplateId);
        this.collectablePeriodTemplateNameValue = collectablePeriodTemplate.name;
        this.$emit(EventTypes.UpdateCollectablePeriodTemplateName, this.collectablePeriodTemplateNameValue);
        if (collectablePeriodTemplateId === DistinctTimeOptionId) {
          // 時間厳守の場合
          this.collectableDistinctTimeValue = this.collectablePeriodStartValue;
          this.$emit(EventTypes.UpdateCollectableDistinctTime, this.collectableDistinctTimeValue);
        } else {
          // 時間厳守以外の普通のテンプレの場合
          this.collectablePeriodStartValue = collectablePeriodTemplate.collectablePeriodStart;
          this.collectablePeriodEndValue = collectablePeriodTemplate.collectablePeriodEnd;
          this.$emit(EventTypes.UpdateCollectablePeriodStart, this.collectablePeriodStartValue);
          this.$emit(EventTypes.UpdateCollectablePeriodEnd, this.collectablePeriodEndValue);
        }
      }
    },
    onChangeCollectableDistinctTime(value: Maybe<string>): void {
      this.$emit(EventTypes.UpdateCollectableDistinctTime, value);
    },
    onChangeCollectablePeriodStart(value: Maybe<number>): void {
      this.$emit(EventTypes.UpdateCollectablePeriodStart, value);

      if (value === undefined) return;

      // NOTE: テンプレート設定中に値を変更せずにイベントが発火する場合はテンプレートを変更しない
      // 例: [午前中] 8:00 ~ 12:00 が設定されているときの time-picker にフォーカスして 8:00 を選択したときに値が変更されていないのにイベントが発火してしまうので、それをガードする
      if (
        this.selectedCollectablePeriodTemplateEntity !== undefined &&
        this.selectedCollectablePeriodTemplateEntity.collectablePeriodStart === value
      )
        return;

      // NOTE: 時間が変更されると、テンプレートを時間指定に変更する
      this.$emit(EventTypes.UpdateCollectablePeriodTemplateName, DesignatedTimePeriodOption.name);
      this.$emit(EventTypes.UpdateCollectablePeriodTemplateId, DesignatedTimePeriodOptionId);
    },
    onChangeCollectablePeriodEnd(value: Maybe<number>): void {
      this.$emit(EventTypes.UpdateCollectablePeriodEnd, value);

      if (value === undefined) return;

      // NOTE: テンプレート設定中に値を変更せずにイベントが発火する場合はテンプレートを変更しない
      // 例: [午前中] 8:00 ~ 12:00 が設定されているときの time-picker にフォーカスして 8:00 を選択したときに値が変更されていないのにイベントが発火してしまうので、それをガードする
      if (
        this.selectedCollectablePeriodTemplateEntity !== undefined &&
        this.selectedCollectablePeriodTemplateEntity.collectablePeriodEnd === value
      )
        return;

      // NOTE: 時間が変更されると、テンプレートを時間指定に変更する
      this.$emit(EventTypes.UpdateCollectablePeriodTemplateName, DesignatedTimePeriodOption.name);
      this.$emit(EventTypes.UpdateCollectablePeriodTemplateId, DesignatedTimePeriodOptionId);
    },
    isDateOnOrAfterUnloadDate(unloadDate: Date, date: Date): boolean {
      return compareAsc(unloadDate, date) <= 0;
    },
    allowedNextBusinessDateChecker(unloadDate: string): boolean {
      return !this.isDateOnOrAfterUnloadDate(parseStringToDate(unloadDate), this.date);
    },
    setUnloadDate(date: Date) {
      if (!this.businessDaysService) throw new Error('Business day service not initialized');
      this.unloadDateValue = this.businessDaysService.getNextBusinessDate(date);
      this.$emit(EventTypes.UpdateUnloadDate, this.unloadDateValue);
    },
    checkDate(): void {
      if (this.preloadStatus === PreloadStatus.Forced) {
        // 宵積みの日付が入っていない場合は、セットする
        if (this.unloadDateValue === undefined) {
          this.setUnloadDate(this.date);
          return;
        }
        // 新しい日付の更新が入った場合に宵積みの日付が前になっていた場合は宵積みの日付をその日付の次の営業日に更新する。
        if (this.isDateOnOrAfterUnloadDate(this.unloadDateValue, this.date)) {
          this.setUnloadDate(this.date);
          this.$context.snackbar.primary('到着日時が荷下ろし日より後の日付のため、荷下ろし日が更新されました。');
        }
      }
    },
  },
});
