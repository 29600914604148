
import Vue, { PropType } from 'vue';
import { AggregatedOrderEntity } from '~/framework/domain/schedule/order/aggregatedOrderEntity';
import { formatPeriod, secsToHhMm, dateToYyyymmddDay } from '~/framework/services/date-time/date-time';
import { Maybe } from '~/framework/typeAliases';

export default Vue.extend({
  name: 'RDialogOrder',
  props: {
    order: {
      type: Object as PropType<Maybe<AggregatedOrderEntity>>,
      required: true,
    },
  },
  computed: {
    getDialogOrderCollectablePeriodText(): string {
      let text = '';
      if (this.order === undefined) return text;
      if (this.order.activePlan.collectablePeriodTemplateName === undefined) return '';
      text += this.order.activePlan.collectablePeriodTemplateName;
      // どちらもある場合はformatPeriod
      if (this.order.activePlan.collectablePeriodStart && this.order.activePlan.collectablePeriodEnd) {
        text += ` ${formatPeriod(
          this.order.activePlan.collectablePeriodStart,
          this.order.activePlan.collectablePeriodEnd
        )}`;
      }
      // startのみの場合(時間厳守)はstartのみ
      else if (
        this.order.activePlan.collectablePeriodTemplateName === '時間厳守' &&
        this.order.activePlan.collectablePeriodStart
      ) {
        text += ` ${secsToHhMm(this.order.activePlan.collectablePeriodStart)}`;
      }
      return text;
    },
  },
  methods: {
    dateToYyyymmddDay,
  },
});
